import React from 'react';
import {CheckListProps} from "../../settings/bookingTypes";

const CleanerCheckList: React.FC<{checklist: CheckListProps[]}> = ({checklist}) => {
  return (
    <fieldset>
      {checklist.map((checklistCategory) => (
        <>
          <legend className="text-base font-semibold leading-6 text-gray-900"> {checklistCategory.name}</legend>
          <div className="mt-4 mb-8 divide-y divide-gray-200 border-b border-t border-gray-200">
            {checklistCategory.items.map((checklistitem) => (
              <div key={checklistitem.id} className="relative flex items-start py-4">
                <div className="min-w-0 flex-1 text-sm leading-6">
                  <label htmlFor={`checklistitem-${checklistitem.id}`}
                         className="select-none font-medium text-gray-900">
                    {checklistitem.description}
                  </label>
                </div>
                <div className="ml-3 flex h-6 items-center">
                  <input
                    id={`checklistitem-${checklistitem.id}`}
                    name={`checklistitem-${checklistitem.id}`}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-hsgreen focus:ring-hsgreen"
                  />
                </div>
              </div>
            ))
            }
          </div>
        </>
      ))
      }
    </fieldset>
  );
};

export default CleanerCheckList;
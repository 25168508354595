import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";

import { setCookie } from './session';
import { post } from './fetch';
import firebaseConfig from "../firebase.config";

class Firebase {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth();
    this.messaging = getMessaging();
  }

  async initializeCloudMessaging(token) {
    // if(!token || (token !== 'gw9P70dmYLjXPIqQ7db0hg' && token !== 'jXJEcMseRmLDLC1TXVMaOA')) {
    //   return;
    // }
    
    try {
      // Get messaging token
      const messagingToken = await getToken(this.messaging, { 
        vapidKey: 'BKaWnL__9BqT40QfG_5bAwu5uupKO3qRC-vyLU2pDnrpsUpVoHl9g9uvMGR7Vxkhw1qyuBYeKWR9hj5LSMzo3m0'
      });
      
      // Send device token to backend for registration
      try {
        const response = await post("/notifications/push-notification/register", {
          deviceToken: messagingToken
        });
        
        if (response.status !== 200) {
          console.error("Failed to register device token");
        }
      } catch (error) {
        console.error("Error registering device token:", error);
      }

      // Initialize service worker for notifications
      if ("serviceWorker" in navigator) {
        try {
          // Unregister existing service worker if it exists
          const existingRegistration = await navigator.serviceWorker.getRegistration("public/firebase-messaging-sw.js");
          if (existingRegistration) {
            await existingRegistration.unregister();
          }

          // Register new service worker
          const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
          await registration.update();

          // Wait for the new service worker to become active
          await new Promise((resolve) => {
            if (registration.active) {
              resolve();
            } else {
              registration.addEventListener("activate", () => resolve());
            }
          });

          // Send token to the service worker
          registration.active?.postMessage({ type: "SET_TOKEN", token });
        } catch (error) {
          console.error("Error initializing service worker:", error);
        }
      }

      return messagingToken;
    } catch (error) {
      console.error("Error initializing cloud messaging:", error);
    }
  }

  async login(email, password) {
    try {
      const firebaseAuth = await signInWithEmailAndPassword(this.auth, email, password);
      const token = await firebaseAuth.user.getIdToken();
      const homespritzAuth = await post("/me/auth", { idToken: token });
      
      if (homespritzAuth.status !== 200) {
        return false;
      } else {
        const body = await homespritzAuth.json();
        setCookie("access_token", body.authToken);
        
        // Initialize cloud messaging with the cleaner ID
        await this.initializeCloudMessaging(body.cleanerId);
        
        await this.auth.signOut();
        return true;
      }
    } catch (e) {
      console.error("Login error:", e);
      return false;
    }
  }

  async changePassword(email) {
    try {
      await sendPasswordResetEmail(this.auth, email);
    } catch (e) {
      console.error("Change password error:", e);
    }
  }

  logout() {
    // Implement logout logic here
  }
}

export default new Firebase();

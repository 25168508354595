import React from "react";

type props = {
  cleanerGets: number;
  subTotal: number;
  cleanerFee: number;
}

const CleanerReceipt: React.FC<props> = ({subTotal, cleanerFee,cleanerGets}) => {
  return (<div  style={{display:'flex', flexDirection:'column'}}>
    <div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <p style={{
        marginBottom: '10px',
        fontSize: '18px',
      }}>New total for you: </p>
      <p style={{color: "#056608", fontSize: '18px'}}>${cleanerGets}</p>
    </div>
  </div>);
}

export default CleanerReceipt;
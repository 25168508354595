const config = {
  apiKey: 'AIzaSyDXVFibrE7EmPHMeMQ8cGfCK_NpnlwzrQo',
  authDomain: 'homespritz-admin.firebaseapp.com',
  databaseURL: 'https://homespritz-admin.firebaseio.com',
  projectId: 'homespritz-admin',
  storageBucket: 'homespritz-admin.appspot.com',
  messagingSenderId: '102733929036',
  appId: '1:102733929036:web:0ebae159b154f9b72d72e9',
  measurementId: 'G-9DQR34T7DL',
};

export default config;

'use client'

import React, { useState, useCallback, useEffect, useRef } from 'react'
import { GoogleMap, LoadScript, Polygon, OverlayView } from '@react-google-maps/api'
import { get, post } from '../helper/fetch'

const mapContainerStyle = {
  width: "100%",
  height: "400px",
}

const center = {
  lat: 51.0447,
  lng: -114.0719,
}

type AreaType = {
  id: number
  name: string
  paths: { lat: number; lng: number }[]
}
type RegionCenter = {
  [key in Region]: { lat: number; lng: number };
};

type Region = "Calgary" | "Edmonton" | "Vancouver";
const regionCenters: RegionCenter = {
  Calgary: { lat: 51.0447, lng: -114.0719 },
  Edmonton: { lat: 53.5461, lng: -113.4938 },
  Vancouver: { lat: 49.2827, lng: -123.1207 },
};

export default function CleanerAreaSelector() {
  const [region, setRegion] = useState<Region>("Calgary");
  const [selectedAreas, setSelectedAreas] = useState<AreaType[]>([]);
  const [existingPolygons, setExistingPolygons] = useState<AreaType[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const mapRef = useRef<google.maps.Map | null>(null);

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newRegion = event.target.value as Region;
    setRegion(newRegion);
    if (mapRef.current) {
      mapRef.current.panTo(regionCenters[newRegion]);
    }
  };

  /**
   * Fetches both existing polygons and user's selected polygons
   */
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setIsLoading(true);

        // Fetch all polygons and selected polygon IDs in parallel
        const [polygonsData, selectedData] = await Promise.all([
          get("/map"),
          get("/map/my")
        ]);

        setExistingPolygons(polygonsData);

        // Find and set selected areas based on the IDs
        const selectedPolygons = polygonsData.filter(polygon =>
          selectedData.includes(polygon.id)
        );
        setSelectedAreas(selectedPolygons);
      } catch (error) {
        console.error("Failed to fetch map data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchData();
  }, []);

  const toggleAreaSelection = useCallback((area: AreaType) => {
    setSelectedAreas(prev =>
      prev.some(a => a.id === area.id)
        ? prev.filter(a => a.id !== area.id)
        : [...prev, area]
    )
  }, [])

  /**
   * Handles saving the selected areas to the server
   * @returns {Promise<void>}
   */
  const handleSave = async (): Promise<void> => {
    try {
      setIsSaving(true);
      setSaveError("");

      await post("/map", selectedAreas);

      // Optional: Show success message or trigger a notification
    } catch (error) {
      setSaveError(typeof error === "string" ? error : "Failed to save selected areas");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Cleaner Area Selector</h1>

      <div className="gap-4">
        <div>
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Select Work Areas</h2>
            <button
              onClick={handleSave}
              disabled={isSaving || selectedAreas.length === 0}
              className={`px-4 py-2 rounded ${isSaving || selectedAreas.length === 0
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600 text-white"
                }`}
            >
              {isSaving ? "Saving..." : "Save Selected Areas"}
            </button>
          </div>

          {saveError && (
            <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
              {saveError}
            </div>
          )}
          <div className="flex flex-col">
            <div className="p-4 bg-gray-100">
              <select
                value={region}
                onChange={handleRegionChange}
                className="block w-full px-3 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="Calgary">Calgary</option>
                <option value="Edmonton">Edmonton</option>
                <option value="Vancouver">Vancouver</option>
              </select>
            </div>
          </div>
          <LoadScript googleMapsApiKey="AIzaSyCm5ChnsE7FPArmt7EiHsGcp99Z7EqbPTM">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={center}
              zoom={10}
              onLoad={map => {
                mapRef.current = map;
              }}
            >
              {isLoading ? (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                  <div className="text-gray-600">Loading map data...</div>
                </div>
              ) : (
                existingPolygons.map(area => (
                  <React.Fragment key={area.id}>
                    <Polygon
                      paths={area.paths}
                      options={{
                        fillColor: selectedAreas.some(a => a.id === area.id) ? "#4CAF50" : "#FF0000",
                        fillOpacity: 0.5,
                        strokeColor: "#000000",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                      }}
                      onClick={() => toggleAreaSelection(area)}
                    />
                  </React.Fragment>
                ))
              )}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </div>
  )
}
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'


const hours = [
  { id: 7, value: '7am', number: 7, unavailable: false },
  { id: 8, value: '8am', number: 8, unavailable: false },
  { id: 9, value: '9am', number: 9, unavailable: false },
  { id: 10, value: '10am', number: 10, unavailable: false },
  { id: 11, value: '11am', number: 11, unavailable: false },
  { id: 12, value: '12am', number: 12, unavailable: false },
  { id: 13, value: '1pm', number: 13, unavailable: false },
  { id: 14, value: '2pm', number: 14, unavailable: false },
  { id: 15, value: '3pm', number: 15, unavailable: false },
  { id: 16, value: '4pm', number: 16, unavailable: false },
  { id: 17, value: '5pm', number: 17, unavailable: false },
  { id: 18, value: '6pm', number: 18, unavailable: false },
  { id: 19, value: '7pm', number: 19, unavailable: false },
  { id: 20, value: '8pm', number: 20, unavailable: false },
  { id: 21, value: '9pm', number: 21, unavailable: false },
  { id: 22, value: '10pm', number: 22, unavailable: false },
  { id: 23, value: '11pm', number: 23, unavailable: false },
]

export default function ListBoxTimes({id, defaultTimeIndex, onSelect, label}) {
  const [selected, setSelected] = useState(hours[defaultTimeIndex]);

  return (
    <div id={id} className="w-36">
      <Listbox
        value={selected}
        onChange={(v)=> {
        setSelected(v);
        onSelect(v);
      }}>
        <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected.value}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-20 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {hours.map((h, hIdx) => (
                <Listbox.Option
                  key={hIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={h}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {h.value}
                      </span>
                      {selected ? (
                        <span className=" left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

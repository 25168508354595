import fetch from 'isomorphic-unfetch';
import {getCookie} from "./session";
const BASE_URL = process.env.REACT_APP_homespritzAPI || 'http://127.0.0.1:5006';
export const get = (url: string, authHeader?: string): Promise<any> => {
	return fetch(BASE_URL + url, {
		method: "GET",
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache': 'no-cache',
			'Authorization': authHeader || getCookie('access_token')
		}
	})
		.then(result => {
			if(result){
				return result.json();
			}else{
				return {};
			}
		})
		.catch(e => {
			throw e
		});
};

export const getPURE = (url: string, authHeader?: string): Promise<any> => {
	return fetch(BASE_URL + url, {
		method: "GET",
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache': 'no-cache',
			'Authorization': authHeader || getCookie('access_token')
		}
	})
		.then(result => {
			if(result){
				return result;
			}else{
				return {};
			}
		})
		.catch(e => {
			throw e
		});
};
export const post = (url: string, data: any, authHeader?: string): Promise<Response> => {
	return fetch(BASE_URL + url, {
		method: "POST",
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache': 'no-cache',
			'Authorization': authHeader || getCookie('access_token')
		},
		body: JSON.stringify(data),
	});
};

export const put = (url: string, data: any,  authHeader?: string): Promise<Response> => {
	return fetch(BASE_URL + url, {
		method: "PUT",
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache': 'no-cache',
			'Authorization': authHeader || getCookie('access_token')
		},
		body: (data) ? JSON.stringify(data) : '',
	});
};

export const del = (url: string, authHeader?: string): Promise<Response> => {
	return fetch(BASE_URL + url, {
		method: "DELETE",
		credentials: 'include',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Cache': 'no-cache',
			'Authorization': authHeader || getCookie('access_token')
		}
	});
};


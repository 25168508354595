import {ConnectAccountOnboarding} from "@stripe/react-connect-js";
import {useState} from "react";

export default function MoneyPage() {
  const [error, setError] = useState<string | null>(null);

  return (
    <>
      {error && <div>{error}</div>}
      <ConnectAccountOnboarding
        onExit={() => {
          console.log("The account has exited onboarding");
        }}
        // Optional: make sure to follow our policy instructions above
        // fullTermsOfServiceUrl="{{URL}}"
        // recipientTermsOfServiceUrl="{{URL}}"
        // privacyPolicyUrl="{{URL}}"
        skipTermsOfServiceCollection={false}
        collectionOptions={{
          fields: 'eventually_due',
          futureRequirements: 'include',
        }}
      />
    </>
  );
}
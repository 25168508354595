import React, {useEffect, useState} from "react";
import {
  ActionContents,
  ActionStatusIcon,
  ActionStatusWrapper,
  ChatInputWrapper,
  CW,
  DateTimeWrapper
} from "./Chat.style";
import Button from "../Button/Button";
import dayjs from 'dayjs';
import requested from "./exclamation.png";
import accepted from "./check-mark.png";
import rejected from "./x-mark.png";
import Extras from "../Extras/Extras";
import CleanerReceipt from "../CleanerReceipt/CleanerReceipt";
import {DeviderLine} from "../../containers/Booking/BookingStyle";
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);
export type Message = {
  sender: {
    name: string;
    role: string;
  },
  createdAt: Date;
  content: string | ChatAction;
}
type BookingExtra = {
  name: string;
  id: number;
}

export enum ChatActionStatus {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export type ChatAction = {
  id: number;
  status: ChatActionStatus;
  text: string;
  details: {
    bookingDateAndTime: {
      startHour: number;
      startMinute: number;
      endHour: number;
      endMinute: number;
      bookingDate: string;
    },
    extras: BookingExtra[];
  }
  newReceipt: Receipt;
}

type Receipt = {
  total: number;
  subTotal: number;
  taxes: number;
  fees: number;
  cleanerGets: number;
}

type props = {
  messagesList: Message[],
  conversationId: number;
  submitNewMessageToBooking: Function;
}
const Chat: React.FC<props> = ({messagesList, conversationId, submitNewMessageToBooking}) => {
  const [newMessage, setNewMessage] = useState('');
  const [messageLoading, setMessageLoading] = useState(false);

  useEffect(() => {
    const chatBar = document.getElementsByClassName('chatList');
    if (chatBar.length > 0) {
      chatBar[0].scrollTop = chatBar[0].scrollHeight;
    }
  }, [messagesList]);

  const makeChangeSet = (action: ChatAction) => {
    const dateTime = action.details.bookingDateAndTime;
    const actionExtras = action.details.extras;
    const receipt = action.newReceipt;
    let dateAndTimeChanges = <></>;
    let newExtras = <><Extras extras={actionExtras as BookingExtra[]} text={'New Add-ons:'}/></>;
    let newReceipt = <></>;
    if (dateTime && dateTime.bookingDate) {
      dateAndTimeChanges = <>
        <DateTimeWrapper>
          <p>
            New
            Date: <b>{dayjs(dateTime.bookingDate).format('MMM DD hh:mm a')} ({dayjs(dateTime.bookingDate).format('dddd')})</b>
          </p>
          <p>
            New
            time: <b>{dateTime.startHour}:{dateTime.startMinute == 0 ? '00' : '30'} - {dateTime.endHour}:{dateTime.endMinute == 0 ? '00' : '30'}</b>
          </p>
        </DateTimeWrapper>
      </>
    }

    if (receipt) {
      newReceipt = <>
        <CleanerReceipt
          cleanerGets={receipt.cleanerGets}
          subTotal={receipt.subTotal}
          cleanerFee={receipt.fees}
        />
      </>
    }
    return (
      <>
        <div>{dateAndTimeChanges}</div>
        {dateTime && dateTime.bookingDate && <DeviderLine style={{marginTop: '20px', marginBottom: '5px'}}/>}
        <div>{newExtras}</div>
        {actionExtras && actionExtras.length > 0 && <DeviderLine style={{marginTop: '20px', marginBottom: '5px'}}/>}
        <div>{newReceipt}</div>
      </>
    )
  }

  return (
    <CW>
      <div className={"chatBar"}>
        <div className={"chatList"}>
          {messagesList && messagesList.map(m => {
            let msgWrapperOwner = 'msg-wrapper-mine';
            let msgClasses = [];
            switch (m.sender.role) {
              case 'admin':
                msgWrapperOwner = 'msg-wrapper-theirs'
                msgClasses = ['theirs', 'theirs-admin'];
                break;
              case 'client':
                msgWrapperOwner = 'msg-wrapper-theirs'
                msgClasses = ['theirs', 'theirs-client'];
                break;
              case 'cleaner':
                msgWrapperOwner = 'msg-wrapper-mine'
                msgClasses = ['mine'];
                break;
            }

            if (typeof m.content === 'string') {
              return <div className={msgWrapperOwner}>
                <div className={['chat', 'chat-message', ...msgClasses].join(' ')}>{m.content}</div>
                <p className={"chat-time"}>{m.sender.name} - {dayjs(m.createdAt).format('MMM DD hh:mm a')}</p>
              </div>
            } else {
              return <div className={msgWrapperOwner}>
                <div className={['chat', 'chat-message', ...msgClasses].join(' ')}>
                  Booking modification:
                  <ActionStatusWrapper>
                    {m.content.status === ChatActionStatus.REQUESTED && <ActionStatusIcon src={requested} alt=""/>}
                    {m.content.status === ChatActionStatus.ACCEPTED && <ActionStatusIcon src={accepted} alt=""/>}
                    {m.content.status === ChatActionStatus.REJECTED && <ActionStatusIcon src={rejected} alt=""/>}
                    <p>{m.content.status.toString()}</p>

                  </ActionStatusWrapper>
                  <DeviderLine style={{marginTop: ''}}/>
                  <ActionContents>
                    {makeChangeSet(m.content)}
                  </ActionContents>
                </div>
                <p className={"chat-time"}>{m.sender.name} - {dayjs(m.createdAt).format('MMM DD hh:mm a')}</p>
              </div>
            }
          })}
        </div>
      </div>

      <ChatInputWrapper>
        <input
          type="text"
          id='msg-input'
          name={'chatInputMain'}
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
          placeholder={'your message...'}
          className={'chat-input'}
        />
        <button
          type='button'
          className="rounded-md bg-lime-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
          onClick={e => {
            e.preventDefault();
            submitNewMessageToBooking(newMessage, conversationId)
          }
          }
        >
          Send
        </button>
      </ChatInputWrapper>
    </CW>
  );
}

export default Chat;

import cookie from 'js-cookie';

export const getCookieFromBrowser = (key: string): any => {
  return cookie.get(key);
};

export const getCookie = (key: string, context?: any) => {
  return getCookieFromBrowser(key);
};

export const setCookie = (key: string, token: any) => {
  cookie.set(key, token, { expires: 14 });
};

export const setCookieExpiery = (key: string, token: any, expiresIn: number) => {
  cookie.set(key, token, { expires: expiresIn });
};

export const removeCookie = (key: string) => {
  cookie.remove(key);
};

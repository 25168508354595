import React from "react";
import {useHistory} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Error, FormFields, FormLabel, FormTitle} from "../../components/FormFields/FormFields";
import {FormWrapper, LogoImage, LogoWrapper, Wrapper} from "./ResetPassword.style";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Logoimage from "../../image/logo-hs.png";
import firebase from "../../helper/firebase";

const initialValues = {
  email: "",
};

const getLoginValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string().required("Email is Required!"),
  });
};

const MyInput = ({field, form, ...props}) => {
  return <Input {...field} {...props} />;
};

export default () => {
  let history = useHistory();

  let resetPassword = async ({email}) => {
    firebase.changePassword(email)
      .then(result => {
        history.replace('/login')
      }).catch(e => {})
  };

  return (
    <Wrapper>
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={resetPassword}
          render={({errors, status, touched, isSubmitting}) => (
            <Form >
              <FormFields>
                <LogoWrapper>
                  <LogoImage src={Logoimage} alt="homespritz-cleaner-portal"/>
                </LogoWrapper>
                <FormTitle>Reset password for cleaner portal</FormTitle>
              </FormFields>

              <FormFields>
                <FormLabel>Email</FormLabel>
                <Field
                  type="email"
                  name="email"
                  component={MyInput}
                  placeholder="cleaner@gmail.com"
                />
                {errors.email && touched.email && (
                  <Error>{errors.email}</Error>
                )}
              </FormFields>
              <button type="submit"
                      className="rounded-md bg-lime-600 w-full px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                      disabled={isSubmitting}
              >
                Reset
              </button>
            </Form>
          )}
          validationSchema={getLoginValidationSchema}
        />
      </FormWrapper>
    </Wrapper>
  );
};

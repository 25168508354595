import React from 'react';
import {ExtraPill, ExtrasWrapper} from "./ExtrasStyle";

type extra = {
  name: string;
  id?: number;
}
type props = {extras: extra[], text?: string;}
const Extras: React.FC<props> = ({extras, text='Add-ons:'}) => {
  return (
      <>
        {extras.length > 0 ? (
            <div>
              <h4 style={{marginBottom: '10px', marginTop: '10px'}}>{text}</h4>
              <ExtrasWrapper>
                {extras.map((extra, i) => {
                  return <ExtraPill>{extra.name}</ExtraPill>
                })}
              </ExtrasWrapper>
            </div>

        ) : (
            <h4>Extras: None</h4>
        )}
      </>

  );
};

export default Extras;
import {styled} from "baseui";

export const ExtrasWrapper = styled("div", ({$theme}) => ({
  display: 'flex',
  flexWrap: 'wrap'
}));

export const ExtraPill = styled("div", ({$theme}) => ({
  borderColor: $theme.colors.darkGrey,
  borderStyle: 'solid',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '400px',
  paddingLeft: '5%',
  paddingRight: '5%',
  paddingBottom: '2px',
  paddingTop: '2px',
  borderWidth: 'thin',
  margin: '2px',
  // maxHeight: '10px'
}));
